/*--------------------------------------------------------------
1 - Responsive Breakpoints
--------------------------------------------------------------*/
@mixin breakpoint($point) {
	// Mobile navigation
	@if ($point == l) {
		@media only screen and (min-width: 993px) {
			@content;
		}
	} @else if ($point == m) {
		@media only screen and (min-width: 601px) {
			@content;
		}
	} @else {
		@media only screen and (min-width: $point) {
			@content;
		}
	}
}

@mixin break($point) {
	@include breakpoint($point) {
		@content;
	}
}
