/*! This file was made by BeyondFloods. Any use of this code without written approval from BeyondFloods is prohibited.
*/

@import 'functions';
@import 'mixins';
@import 'extras';

/*
* > Setup
*/

/*
* >> Fonts
*/
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

$fh: #{'Montserrat', sans-serif};
$fb: #{'Montserrat', sans-serif};

p,
h1,
h2,
h3,
h4,
h5,
a,
span,
div {
	-webkit-font-smoothing: antialiased;
}

/*
* >> Color Variables
*/

// main colors
$c-blue: #007fbd;
$c-red: #cc3333;

// Common Colors
$c-white: #ffffff;
$c-black: #000;
$c-grey: #8d8d8d;
$c-grey-text: #8d8d8d;
$c-grey-light: #bababa;
$c-grey-dark: #292929;
$c-body-background: #f5f5f5;

// text colors
$c-text: #000000;
$c-link: $c-red;

/*
* >> Background placeholders
*/

%cover {
	background-size: cover;
	background-position: 50% 50%;
}

%fontLarge {
	@include break(m) {
		font-size: em(32);
		line-height: em(36);
	}
	font-size: em(18);
	line-height: em(22);
	letter-spacing: em(1);
	font-weight: 700;
}

%fontMedium {
	@include break(m) {
		font-size: em(24);
		line-height: em(30);
	}
	font-size: em(18);
	line-height: em(22);
	font-weight: 500;
}

%fontSmall {
	@include break(m) {
		letter-spacing: em(0.5);
	}
	font-size: em(18);
	line-height: em(22);
	font-weight: 500;
}

/*
* > General Body & HTML
*/
html.bf-qq {
	font-size: 14px;
	img {
		display: inline-block;
		max-width: 100%;
	}
	.container {
		width: 90%;
		max-width: 1300px;
		margin: 0 auto;
		padding-bottom: 20px;
	}
	.bf-logo {
		display: block;
		width: 550px;
		max-width: 100%;
		margin: 20px auto 40px;
	}
	.bf-title {
		@extend %fontLarge;
		text-transform: uppercase;
		color: $c-blue;
		margin: 20px auto;
		@include break(m) {
			margin-bottom: 40px;
		}
		text-align: center;
	}
	.bf-footer {
		text-align: center;
		color: #8d8d8d;
		a {
			color: #8d8d8d;
			text-decoration-line: underline;
		}
	}
	.bf-p {
		@extend %fontSmall;
		color: $c-black;
	}
	.bf-unavailable {
		.bf-p {
			text-align: center;
			@include break(m) {
				text-align: left;
			}
		}
		.bf-form-container {
			max-width: 100%;
			margin: 0 auto;
			fieldset {
				text-align: left;
				width: 500px;
				max-width: 100%;
				margin: 15px 0 0 auto;
			}
			.bf-disclaimer{
				padding: 1px;
				text-align: left;
				color:#8d8d8d;
				padding-bottom: 20px ;
				font-style: italic;
			}
		}
	}
	.bf-contact-wrapper {
		// text-align: center;
		.bf-contact {
			@extend %fontSmall;
		}
		.bf-contact-info {
			display: block;
			margin: 10px auto;
			@extend %fontSmall;
			color: $c-grey;
			i {
				margin-right: 7px;
			}
			a {
				color: $c-grey;
			}
		}
	}
	.bf-title-address {
		color: $c-grey-text;
		@extend %fontMedium;
		text-align: center;
		margin: 20px auto;
		@include break(m) {
			margin-bottom: 40px;
		}
		.fas {
			margin-right: 9px;
		}
	}
	form.bf-form {
		.bf-input-wrapper {
			position: relative;
			i {
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				left: 10px;
				color: $c-grey;
				@extend %fontMedium;
			}
			input {
				@extend %fontMedium;
				height: 60px;
				padding: 1px 10px 1px 45px;
				border-radius: 5px;
				border: 1px solid #d4d4d4;
				border-radius: 8px;
				background-color: $c-white;
				box-sizing: border-box;
				box-shadow: none;
				margin: 15px auto;
				&::placeholder {
					font-weight: 400;
				}
			}
		}
		fieldset {
			margin-top: 30px;
			display: block;
			border: none;
			legend {
				@extend %fontMedium;
				span {
					color: $c-grey;
					display: block;
					margin-top: 20px;
					@extend %fontSmall;
				}
			}
			label {
				margin: 10px auto;
				display: block;
				@extend %fontSmall;
				color: $c-grey;
			}
			input {
				width: 20px;
				height: 20px;
				vertical-align: middle;
				position: relative;
				opacity: 1;
				pointer-events: inherit;
				margin-right: 10px;
			}
		}

		
	}
	.bf-button-wrapper {
		text-align: center;
		button,
		a {
			display: inline-block;
			width: 240px;
			max-width: 100%;
			margin: 10px;
			@extend %fontSmall;
			text-transform: uppercase;
			padding: 9px 0;
			border: 2px solid $c-blue;
			border-radius: 5px;
			background: transparent;
			color: $c-blue;
			transition: all 0.2s ease;
			cursor: pointer;
			.fas {
				margin: 0 4px;
			}
			&:hover {
				background: $c-blue;
				box-shadow: 0px 0px 10px 0 rgba($c-blue, 0.75);
				color: $c-white;
			}
		}
	}
	.bf-loading-spinner-wrapper {
		width: 100%;
		height: 100%;
		background: #130f41;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1000;
		overflow: hidden;
		.bird-spin {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			max-width: 90%;
			display: inline-block;
			border-radius: 50%;
			// width: 200px;
		}
		.loading-spinner-placeholder{
			position: absolute;
			
			top:80% ;
			left:10%;
			animation: flowing 10s linear infinite;
			transform: translate(-50%, -50%);
			max-width: 100%;
			// display: inline-block;
			color: #bababa;
			font-size: 25px;
			span{
				white-space: nowrap;
			}
		}
		@keyframes flowing {
			100% {
				transform: translateX(-100%);
			}
		}
	}
}

body {
	background: $c-body-background;
	font-family: $fb;
	color: $c-text;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: $fh;
}

a,
a:visited,
a:hover,
a:focus,
a:active {
	color: $c-link;
}

p,
h1,
h2,
h3,
h4,
h5,
a,
span,
div {
	-webkit-font-smoothing: antialiased;
}

/**
 * >> Sections
 */

html.bf-qq {
	/**
		*>>> Step 1 - Address Page
	*/
	// overflow: hidden;
	.pac-container {
		box-shadow: 0 2px 4px 0 rgba($c-black, 0.01);
	}
	.bf-address-q {
		padding: 20px 0;
		text-align: center;
		.bf-autocomplete-wrapper {
			text-align: left;
			width: 790px;
			max-width: 100%;
			margin: 0 auto;
			.bf-autocomplete-form-wrapper {
				text-align: center;
				.search-location-input {
					position: relative;
					margin: 0 auto;
					display: block;
					width: 100%;
					margin: 0;
					border: 1px solid #d4d4d4;
					background: $c-white;
					transition: all 200ms ease;
					@include break(m) {
						display: inline-block;
						width: 550px;
						max-width: 69.62%;
					}
					input {
						border: none;
						padding: 0 12px;
						margin: 0;
						box-sizing: border-box;
						&:focus {
							border: none;
							box-shadow: none;
							margin: 0;
						}
					}
					.bf-dropdown {
						text-align: left;
						position: absolute;
						z-index: 999;
						left: 0;
						right: 0;
						top: 100%;
						display: inline-block;
						div {
							cursor: pointer;
							padding: 3px 10px;
						}
						&:after {
							content: '';
							padding: 1px 1px 1px 0;
							height: 18px;
							box-sizing: border-box;
							text-align: right;
							display: block;
							background-image: url(https://maps.gstatic.com/mapfiles/api-3/images/powered-by-google-on-white3.png);
							background-position: right;
							background-repeat: no-repeat;
							background-size: 120px 14px;
							background-color: $c-white;
						}
					}
					&:focus-within {
						border-bottom-right-radius: 0;
						border-color: rgba(223, 225, 229, 0);
						box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
						// border-radius: 24px;
						background: #fff;
					}
					&:hover {
						box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
						// border-color: rgba(223, 225, 229, 0);
					}
				}
				.bf-button {
					display: block;
					width: 100%;
					@include break(m) {
						display: inline-block;
						width: 240px;
						max-width: 30.38%;
					}
					margin: 0;
					@extend %fontSmall;
					text-transform: uppercase;
					padding: 10.5px 0;
					border: none;
					background: transparent;
					transition: all 0.2s ease;
					cursor: pointer;
					background: $c-blue;
					color: $c-white;
					.fas {
						margin: 0 4px;
					}
					&:hover {
						box-shadow: 0px 0px 10px 0 rgba($c-blue, 0.75);
					}
				}
			}
		}
		.bf-manual-wrapper {
			text-align: left;
			width: 790px;
			max-width: 100%;
			margin: 0 auto;
			.bf-manual-address-form {
				input,
				select {
					display: inline-block;
					box-sizing: border-box;
					width: 100%;
					background: $c-white;
					border: 1px solid rgba($c-grey-light, 0.7);
					margin-bottom: 10px;
					padding-left: 10px;
					:disabled {
						color: $c-grey-light;
					}
					&::placeholder {
						color: $c-grey-light;
					}
					&:focus-within,
					&:focus,
					&:active,
					&:target,
					&:target-within,
					&:focus-within,
					&:scope {
						border-bottom: 1px solid rgba($c-grey-light, 0.7) !important;
						box-shadow: none !important;
						outline: none;
					}
				}
				@include break(m) {
					.bf-city-wrapper {
						width: calc(55% - 20px);
						margin-right: 20px;
						display: inline-block;
					}
					.bf-state-wrapper {
						display: inline-block;
						width: calc(20% - 20px);
						margin-right: 20px;
					}
					.bf-zip-wrapper {
						display: inline-block;
						width: calc(25%);
					}
				}
				.bf-disclaimer{
					padding: 1px;
					color:#8d8d8d;
					padding-bottom: 20px ;
				}
				.bf-button-wrapper {
					text-align: left;
					button {
						margin: 10px 0;
					}
				}
			}
		}
		button.bf-address-toggle {
			margin-top: 10px;
			border: none;
			background: transparent;
			padding: 0;
			text-decoration: underline;
			color: $c-blue;
			cursor: pointer;
		}
		.bf-home-container {
			margin: 40px auto;
			.bf-state-list {
				margin: 20px auto;
				display: inline-block;
				width: 550px;
				max-width: 90%;
				h4 {
					@extend %fontMedium;
					margin-bottom: 25px;
				}
				.bf-natgetn-logo {
					margin: 25px auto;
				}
				p {
					margin: 25px auto;
					@extend %fontSmall;
				}
			}
		}
		.bf-map {
			display: inline-block;
			width: 550px;
			max-width: 90%;
			margin: 20px auto;
		}
	}
	/**
        *>>> Step 2 & Step 3
    */
	.bf-building-q {
		display: inline-block;
		width: 100%;
		vertical-align: middle;
		padding: 20px 0 60px;
		.bf-header {
			.bf-logo {
				display: block;
				width: 550px;
				max-width: 100%;
				margin: 20px auto;
			}
			h3 {
				text-align: center;
				margin: 20px auto;
				@extend %fontMedium;
			}
		}

		form.bf-building-selection {
			.l2 {
				@include break(l) {
					width: 20%;
				}
				&.offset-l2 {
					@include break(l) {
						margin-left: 10%;
					}
				}
				&.offset-l4 {
					@include break(l) {
						margin-left: 20%;
					}
				}
			}
			input[type='radio'] {
				display: none;
				&:not(:disabled) ~ label {
					cursor: pointer;
				}
				&:disabled ~ label {
					color: hsla(150, 5%, 75%, 1);
					border-color: hsla(150, 5%, 75%, 1);
					box-shadow: none;
					cursor: not-allowed;
				}
			}
			label {
				display: inline-block;
				width: 100%;
				max-width: 100%;
				background: white;
				border: 1px solid #d4d4d4;
				border-radius: 10px;
				color: $c-grey-text;
				margin: 10px auto;
				.bf-label-wrapper {
					padding: 20px 10px;
					.bf-icon-wrapper {
						width: 90%;
						max-width: 90%;
						margin: 0 auto;
						position: relative;
						height: 50px;
						@include break(m) {
							height: 100px;
						}
						.bf-icon {
							position: absolute;
							left: 0;
							right: 0;
							bottom: 0;
							margin: 0 auto;
							display: inline-block;
							max-width: 100%;
							transition: all 200ms ease;
							max-height: 50px;
							@include break(m) {
								max-height: 100px;
							}
							&-standard {
								opacity: 1;
							}
							&-hover {
								opacity: 0;
							}
						}
					}
				}
				box-shadow: 0px 4px 15px 0px rgba($c-black, 0.1);
				position: relative;
				transition: all 0.3s ease;
				&:hover {
					border-color: $c-red;
					background: $c-red;
					color: $c-white;
					box-shadow: 0px 0px 20px rgba($c-red, 0.75);
					&.bf-sublabel {
						background: #009900;
						border-color: #009900;
						box-shadow: 0 0 20px rgba(#009900, 0.75);
					}
					.bf-label-wrapper .bf-icon-wrapper .bf-icon {
						&-standard {
							opacity: 0;
						}
						&-hover {
							opacity: 1;
						}
					}
				}
				.bf-label-text-wrapper {
					display: block;
					position: relative;
					min-height: 72px;
					h4 {
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
						width: 100%;
						@extend %fontSmall;
						text-transform: uppercase;
						margin: 0 auto;
						span {
							display: block;
							font-size: em(12);
							line-height: em(14);
						}
					}
				}
			}
			input[type='radio']:checked + label {
				border-color: $c-red;
				background: $c-red;
				color: $c-white;
				box-shadow: 0px 0px 20px rgba($c-red, 0.75);
				.bf-label-wrapper .bf-icon-wrapper .bf-icon {
					&-standard {
						opacity: 0;
					}
					&-hover {
						opacity: 1;
					}
				}
			}
		}
	}
	.bf-foundation-q,
	.bf-step-three {
		display: inline-block;
		width: 100%;
		vertical-align: middle;
		padding: 20px 0 60px;
		.bf-header {
			.bf-logo {
				display: block;
				width: 550px;
				max-width: 100%;
				margin: 20px auto;
			}
			h3 {
				text-align: center;
				margin: 20px auto;
				@extend %fontMedium;
			}
		}

		form {
			.l2 {
				@include break(l) {
					width: 20%;
				}
				&.offset-l2 {
					@include break(l) {
						margin-left: 20%;
					}
				}
			}
		}
		input[type='radio'] {
			display: none;
			&:not(:disabled) ~ label {
				cursor: pointer;
			}
			&:disabled ~ label {
				color: hsla(150, 5%, 75%, 1);
				border-color: hsla(150, 5%, 75%, 1);
				box-shadow: none;
				cursor: not-allowed;
			}
		}
		label {
			display: inline-block;
			width: 100%;
			max-width: 100%;
			background: white;
			border: 1px solid #d4d4d4;
			border-radius: 10px;
			color: $c-grey-text;
			@include break(m) {
				margin: 0 auto;
			}
			margin: 10px auto;
			.bf-label-wrapper {
				padding: 20px 10px;
				.bf-icon-wrapper {
					display: inline-block;
					width: 90%;
					max-width: 90%;
					margin: 0 auto;
					position: relative;
					height: 50px;
					@include break(m) {
						height: 100px;
					}
					.bf-icon {
						position: absolute;
						left: 0;
						right: 0;
						bottom: 0;
						margin: 0 auto;
						display: inline-block;
						max-width: 100%;
						transition: all 200ms ease;
						max-height: 50px;
						@include break(m) {
							max-height: 100px;
						}
						&-standard {
							opacity: 1;
						}
						&-hover {
							opacity: 0;
						}
					}
				}
			}
			box-shadow: 0px 4px 15px 0px rgba($c-black, 0.1);
			position: relative;
			transition: all 0.3s ease;
			&:hover {
				border-color: $c-red;
				background: $c-red;
				color: $c-white;
				box-shadow: 0px 0px 20px rgba($c-red, 0.75);
				&.bf-sublabel {
					background: #009900;
					border-color: #009900;
					box-shadow: 0 0 20px rgba(#009900, 0.75);
				}
				.bf-label-wrapper .bf-icon-wrapper .bf-icon {
					&-standard {
						opacity: 0;
					}
					&-hover {
						opacity: 1;
					}
				}
			}
			.bf-label-text-wrapper {
				display: block;
				position: relative;
				min-height: 72px;
				h4 {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					width: 100%;
					@extend %fontSmall;
					text-transform: uppercase;
					margin: 0 auto;
					span {
						font-size: em(14);
					}
				}
			}
		}
		input[type='radio']:checked + label {
			border-color: $c-red;
			background: $c-red;
			color: $c-white;
			box-shadow: 0px 0px 20px rgba($c-red, 0.75);
			.bf-label-wrapper .bf-icon-wrapper .bf-icon {
				&-standard {
					opacity: 0;
				}
				&-hover {
					opacity: 1;
				}
			}
		}
	}
	/**
        *>>> Step 4
    */
	.bf-step-four {
		.bf-input {
			display: block;
			width: 550px;
			max-width: 100%;
			margin: 10px auto;
			h3 {
				@extend %fontSmall;
				text-transform: uppercase;
				color: $c-grey-text;
			}
			input {
				height: 60px;
				@extend %fontMedium;
				padding: 1px 10px;
				border-radius: 5px;
				border: 1px solid #d4d4d4;
				border-radius: 8px;
				background-color: $c-white;
				box-sizing: border-box;
				box-shadow: none;
				&:focus {
					box-shadow: none;
					border-bottom: 1px solid #d4d4d4;
				}
			}
		}
		.bf-dicription{
			color:#8d8d8d;
			padding-left: 2%;
			padding-bottom: 4%;
		}
		.bf-radio {
			input[type='radio'] {
				display: none;
				height: 0;
				&:not(:disablfed) ~ label {
					cursor: pointer;
				}
				&:disabled ~ label {
					color: hsla(150, 5%, 75%, 1);
					border-color: hsla(150, 5%, 75%, 1);
					box-shadow: none;
					cursor: not-allowed;
				}
			}
			

			label {
				display: inline-block;
				width: 90px;
				max-width: 100%;
				height: 90px;
				background: white;
				border: 1px solid #d4d4d4;
				border-radius: 8px;
				cursor: pointer;
				margin-bottom: 1rem;
				//margin: 1rem;
				text-align: center;
				position: relative;
				transition: all 0.3s ease;
				&:hover {
					border-color: #cc3333;
					box-shadow: 0px 0px 10px rgba(#cc3333, 0.5);
				}
				h4 {
					position: absolute;
					top: 50%;
					left: 50%;
					width: 95%;
					transform: translate(-50%, -50%);
					margin: 0 auto;
					@extend %fontLarge;
					span {
						display: block;
						@extend %fontSmall;
					}
				}
			}
			input[type='radio']:checked + label {
				background: #cc3333;
				color: $c-white;
				box-shadow: 0px 0px 10px rgba(#cc3333, 0.6);
				border-color: #cc3333;
			}
			p {
				font-weight: 900;
			}
			&-family {
				label {
					width: 100%;
					h4 {
						@extend %fontMedium;
					}
				}
			}
		}
	}
	/**
        *>>> Outlook Page
    */
	.bf-outlook-container {
		padding-bottom: 40px;
		.bf-outlook-wrapper {
			vertical-align: top;
			.bf-map-container {
				display: block;
				max-width: 100%;
				margin: 10px auto;
				@include break(m) {
					height: 265px;
					width: 800px;
					display: inline-block;
					max-width: calc(70% - 30px);
					margin: 15px;
					position: relative;
					overflow: hidden;
				}
				img {
					vertical-align: top;
					border-radius: 10px;
					@include break(m) {
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
					}
					width: 800px;
					max-width: 100%;
				}
			}
			.bf-risk-score {
				display: block;
				max-width: 100%;
				@include break(m) {
					display: inline-block;
					margin: 15px;
					vertical-align: top;
					max-width: calc(30% - 30px);
				}
				text-align: center;
				h3 {
					font-size: em(60);
				}
				h3,
				h5 {
					text-align: left;
					margin-top: 0;
					font-weight: 900;
					vertical-align: middle;
					margin-bottom: 0;
					display: inline-block;
					&.very-low {
						color: #136400;
					}
					&.low {
						color: #896136;
					}
					&.moderate {
						color: #ce621a;
					}
					&.high {
						color: #e31a1c;
					}
					&.very-high {
						color: #990000;
					}
					padding-right: 25px;
				}
				h5 {
					padding-right: 0;
					@extend %fontSmall;
					font-weight: 500;
					b {
						text-transform: uppercase;
						display: block;
					}
					span {
						color: #000;
					}
				}
				.bf-score-meter {
					margin-top: 20px;
					border-radius: 10px;
					background: $c-white;
					box-shadow: 0 0 6px 0 rgba($c-grey, 0.5);
					padding: 10px;
					img {
						max-width: 80%;
					}
				}
			}
		}
		.bf-flood-issues {
			p {
				@extend %fontLarge;
				color: $c-text;
				text-align: center;
				span {
					color: $c-red;
				}
			}
			h5 {
				@extend %fontMedium;
			}
			.bf-outlook-data {
				margin-top: 40px;
				.bf-disclaimer{
					padding: 1px;
					color:#8d8d8d;
					padding-bottom: 20px ;
					font-style: italic;

				}
			}
		}
		.bf-disclaimer-wrapper{
			.bf-disclaimer{
				padding: 1px;
				color:#8d8d8d;
				padding-bottom: 20px ;
				font-style: italic;
			}
		}
	}
	/**
        *>>> Results Page
    */
	.bf-result-container {
		.row {
			margin-bottom: 0;
		}
		.bf-logo {
			margin-bottom: 20px;
		}
		.bf-title-address {
			margin-bottom: 20px;
		}
		.bf-title {
			margin-bottom: 20px;
			span {
				display: block;
				@extend %fontSmall;
				text-transform: none;
				color: $c-grey;
			}
		}
		.bf-quote-referral {
			.bf-title {
				margin: 0 auto;
				@extend %fontMedium;
				text-transform: none;
			}
		}
		.bf-thanks {
			max-width: 100%;
			margin: 0 auto;
			@extend %fontSmall;
		}
		.bf-contact {
			max-width: 100%;
			margin: 20px auto;
			@extend %fontSmall;
		}
		.bf-quote-referral {
			.bf-title {
				color: $c-red;
			}
		}

		.bf-quote-wrapper {
			margin-top: 20px;
			align-items: center;
			@include break(m) {
				display: flex;
			}
			.bf-quote-block {
				@include break(m) {
					flex-basis: 33%;
				}
				background: #fff;
				box-shadow: 0 1px 3px 0 rgba(139, 139, 139, 0.4);
				border: 6px solid #ddd;
				margin: 30px auto;
				transition: all 0.2s ease;
				.bf-quote-header {
					transition: all 0.2s ease;
					padding: 0 15px 20px;
					position: relative;
					background: #ddd;
					min-height: 246px;
					.bf-best-fit-banner {
						text-align: center;
						background: rgba(0, 0, 0, 0.3);
						padding: 20px 5px;
						margin: -6px -21px 40px;
						h4 {
							margin: 0 auto;
							@extend %fontLarge;
							text-transform: uppercase;
						}
					}
					h4,
					h5 {
						@extend %fontLarge;
						color: #fff;
						margin: 0 0 10px;
					}
					h6 {
						@extend %fontMedium;
						color: $c-white;
						margin: 16px auto;
					}
					p {
						@extend %fontSmall;
						color: #fff;
						min-height: 54px;
					}
					button.bf-select {
						position: absolute;
						top: 96px;
						right: 5%;
						width: 100px;
						border: 2px solid #fff;
						background: transparent;
						border-radius: 5px;
						text-transform: uppercase;
						color: #fff;
						padding: 8px 0;
						transition: all 0.2s ease;
						cursor: pointer;
						&:hover {
							background: rgba(255, 255, 255, 0.8);
							color: #000;
						}
						&:focus {
							outline: none;
						}
					}
				}
				.bf-quote-body {
					padding: 20px 0;
					transition: all 0.2s ease;
					min-height: 794px;
					.bf-warn {
						margin: 0 0 20px;
						color: #cb3333;
						@extend %fontSmall;
						padding: 0 15px;
					}
					& > div {
						padding: 15px;
						text-align: center;
						strong {
							display: block;
						}
						&:nth-of-type(odd) {
							background: #f5f5f5;
						}
					}
					.bf-extras {
						background: #fff;
						min-height: 250px;
						strong {
							display: inline;
							margin-right: 4px;
						}
					}
				}
				&.bf-essential {
					border-color: #0b7886;
					.bf-quote-header {
						background: #0b7886;
					}
					&.disabled {
						.bf-quote-body {
							// background: #0b7886;
						}
					}
				}
				&.bf-enhanced {
					border-color: #cb3333;
					.bf-quote-header {
						background: #cb3333;
					}
					&.disabled {
						.bf-quote-body {
							// background: #cb3333;
						}
					}
				}
				&.bf-elite {
					border-color: #007fbd;
					.bf-quote-header {
						background: #007fbd;
					}
					&.disabled {
						.bf-quote-body {
							// background: #007fbd;
						}
					}
				}
				&.bf-best-fit {
					margin-top: 0;
					flex-basis: 34%;
					margin-bottom: 0;
					&.bf-essential {
						border-color: #0b7886;
						.bf-quote-header {
							background: #0b7886;
						}
					}
					&.bf-enhanced {
						border-color: #cb3333;
						.bf-quote-header {
							background: #cb3333;
						}
					}
					&.bf-elite {
						border-color: #007fbd;
						.bf-quote-header {
							background: #007fbd;
						}
					}
					.bf-quote-header {
						padding-top: 0;
						.bf-select {
							top: 96px;
							&:hover {
								background: #fff;
							}
							&:focus {
								outline: none;
							}
						}
					}
					.bf-quote-body {
						padding-top: 60px;
						padding-bottom: 60px;
					}
				}
			}
		}
	}
	/**
	Bind Now Page
	*/
	.bf-bind-now {
		.bf-quote-wrapper {
			margin-top: 20px;
			align-items: center;
			.bf-quote-block {
				background: #fff;
				box-shadow: 0 1px 3px 0 rgba(139, 139, 139, 0.4);
				border: 6px solid #ddd;
				margin: 30px auto;
				transition: all 0.2s ease;
				.bf-quote-header {
					transition: all 0.2s ease;
					padding: 0 15px 20px;
					position: relative;
					background: #ddd;
					min-height: 246px;
					.bf-best-fit-banner {
						text-align: center;
						background: rgba(0, 0, 0, 0.3);
						padding: 20px 5px;
						margin: -6px -21px 40px;
						h4 {
							margin: 0 auto;
							@extend %fontLarge;
							text-transform: uppercase;
						}
					}
					h4,
					h5 {
						@extend %fontLarge;
						color: #fff;
						margin: 0 0 10px;
					}
					h6 {
						@extend %fontMedium;
						color: $c-white;
						margin: 16px auto;
					}
					p {
						@extend %fontSmall;
						color: #fff;
						min-height: 54px;
					}
					button.bf-select {
						position: absolute;
						top: 20px;
						right: 5%;
						width: 100px;
						border: 2px solid #fff;
						background: transparent;
						border-radius: 5px;
						text-transform: uppercase;
						color: #fff;
						padding: 8px 0;
						transition: all 0.2s ease;
						cursor: pointer;
						&:hover {
							background: rgba(255, 255, 255, 0.8);
							color: #000;
						}
						&:focus {
							outline: none;
						}
					}
				}
				.bf-quote-body {
					padding: 20px 0;
					transition: all 0.2s ease;
					min-height: 794px;
					.bf-warn {
						margin: 0 0 20px;
						color: #cb3333;
						@extend %fontSmall;
						padding: 0 15px;
					}
					& > div {
						padding: 15px;
						text-align: center;
						strong {
							display: block;
						}
						&:nth-of-type(odd) {
							background: #f5f5f5;
						}
					}
					.bf-extras {
						background: #fff;
						min-height: 250px;
						strong {
							display: inline;
							margin-right: 4px;
						}
					}
				}
				&.bf-essential {
					border-color: #0b7886;
					.bf-quote-header {
						background: #0b7886;
					}
					&.disabled {
						.bf-quote-body {
							// background: #0b7886;
						}
					}
				}
				&.bf-enhanced {
					border-color: #cb3333;
					.bf-quote-header {
						background: #cb3333;
					}
					&.disabled {
						.bf-quote-body {
							// background: #cb3333;
						}
					}
				}
				&.bf-elite {
					border-color: #007fbd;
					.bf-quote-header {
						background: #007fbd;
					}
					&.disabled {
						.bf-quote-body {
							// background: #007fbd;
						}
					}
				}
				&.bf-best-fit {
					margin-top: 0;
					flex-basis: 34%;
					margin-bottom: 0;
					&.bf-essential {
						border-color: #0b7886;
						.bf-quote-header {
							background: #0b7886;
						}
					}
					&.bf-enhanced {
						border-color: #cb3333;
						.bf-quote-header {
							background: #cb3333;
						}
					}
					&.bf-elite {
						border-color: #007fbd;
						.bf-quote-header {
							background: #007fbd;
						}
					}
					.bf-quote-header {
						padding-top: 0;
						min-height: 265px;

						.bf-select {
							top: 96px;
							&:hover {
								background: #fff;
							}
							&:focus {
								outline: none;
							}
						}
					}
					.bf-quote-body {
					}
				}
			}
		}
		.bf-bind-form {
			margin-top: 20px;
			border: 2px solid rgba(0, 0, 0, 0.3);
			padding: 20px 5px;
		}
		.bf-input {
			display: block;
			width: 550px;
			max-width: 100%;
			margin: 10px auto;
			h3 {
				@extend %fontSmall;
				text-transform: uppercase;
				color: $c-grey-text;
			}
			input {
				height: 60px;
				@extend %fontMedium;
				padding: 1px 10px;
				border-radius: 5px;
				border: 1px solid #d4d4d4;
				border-radius: 8px;
				background-color: $c-white;
				color: #000;
				box-sizing: border-box;
				box-shadow: none;
				&:focus {
					box-shadow: none;
					border-bottom: 1px solid #d4d4d4;
				}
			}
		}
		.bf-radio {
			input[type='radio'] {
				display: none;
				height: 0;
				&:not(:disablfed) ~ label {
					cursor: pointer;
				}
				&:disabled ~ label {
					color: hsla(150, 5%, 75%, 1);
					border-color: hsla(150, 5%, 75%, 1);
					box-shadow: none;
					cursor: not-allowed;
				}
			}
			label {
				display: inline-block;
				width: 100%;
				max-width: 100%;
				background: white;
				border: 1px solid #d4d4d4;
				border-radius: 8px;
				cursor: pointer;
				color: #000;
				margin-bottom: 1rem;
				//margin: 1rem;
				text-align: center;
				position: relative;
				transition: all 0.3s ease;
				&:hover {
					border-color: #cc3333;
					box-shadow: 0px 0px 10px rgba(#cc3333, 0.5);
				}
				h4 {
					margin: 0 auto;
					@extend %fontMedium;
					padding: 20px 10px;
				}
			}
			input[type='radio']:checked + label {
				background: #cc3333;
				color: $c-white;
				box-shadow: 0px 0px 10px rgba(#cc3333, 0.6);
				border-color: #cc3333;
			}
			p {
				font-weight: 900;
			}
			&-family {
				label {
					width: 100%;
					h4 {
						@extend %fontMedium;
					}
				}
			}
		}
		.bf-button-wrapper {
			margin-top: 40px;
		}
	}
}
