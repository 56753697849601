.app {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	color: white;
	padding-top: 12%;
	background: #000;
}

body {
	height: 100vh;
	background: #f5f5f5;
}

.container {
	width: 90%;
	max-width: 1280px;
	margin: 0 auto;
}
