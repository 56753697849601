html.bf-qq {
	/*--------------------------------------------------------------
Vertical Align call
--------------------------------------------------------------*/
	%valignCol {
		font-size: 0rem;
		.col {
			font-size: 1rem;
			float: none !important;
			display: inline-block;
			vertical-align: middle;
		}
	}

	/**
		.hide	Hidden for all Devices
		.hide-on-small-only	Hidden for Mobile Only
		.hide-on-med-only	Hidden for Tablet Only
		.hide-on-med-and-down	Hidden for Tablet and Below
		.hide-on-med-and-up	Hidden for Tablet and Above
		.hide-on-large-only	Hidden for Desktop Only
	*/
	.center-align {
		&-on-small-only {
			@media only screen and (max-width: 600px) {
				text-align: center;
			}
		}
		&-on-med-only {
			@media only screen and (max-width: 992px) and (min-width: 600px) {
				text-align: center;
			}
		}
		&-on-med-and-down {
			@media only screen and (max-width: 992px) {
				text-align: center;
			}
		}
		&-on-med-and-up {
			@include break(m) {
				text-align: center;
			}
		}
		&-on-large-only {
			@media only screen and (min-width: 993px) {
				text-align: center;
			}
		}
	}
	.left-align {
		&-on-small-only {
			@include breakpoint(s) {
				text-align: left;
			}
		}
		&-on-med-only {
			@media only screen and (max-width: 992px) and (min-width: 600px) {
				text-align: left;
			}
		}
		&-on-med-and-down {
			@media only screen and (max-width: 992px) {
				text-align: left;
			}
		}
		&-on-med-and-up {
			@include break(m) {
				text-align: left;
			}
		}
		&-on-large-only {
			@media only screen and (min-width: 993px) {
				text-align: left;
			}
		}
	}
	.right-align {
		&-on-small-only {
			@include breakpoint(s) {
				text-align: right;
			}
		}
		&-on-med-only {
			@media only screen and (max-width: 992px) and (min-width: 600px) {
				text-align: right;
			}
		}
		&-on-med-and-down {
			@media only screen and (max-width: 992px) {
				text-align: right;
			}
		}
		&-on-med-and-up {
			@include break(m) {
				text-align: right;
			}
		}
		&-on-large-only {
			@media only screen and (min-width: 993px) {
				text-align: right;
			}
		}
	}

	.valign-children {
		@extend %valignCol;
		.middle-valign {
			vertical-align: middle;
		}
		.top-valign {
			vertical-align: top;
		}
		.bottom-valign {
			vertical-align: bottom;
		}
	}

	.middle-valign {
		vertical-align: middle;
	}
	.top-valign {
		vertical-align: top;
	}
	.bottom-valign {
		vertical-align: bottom;
	}
}