.bf-quoteref {
	text-align: center;
	padding-top: 60px;
	.bf-logo {
		display: block;
		width: 600px;
		max-width: 100%;
		margin: 0 auto;
	}
	.bf-quoteref-wrapper {
		padding-bottom: 40px;
		h1 {
			font-size: 36px;
			line-height: 44px;
			text-transform: uppercase;
		}
		h3 {
			font-size: 36px;
			line-height: 44px;
			text-transform: uppercase;
            color: #006597;
			strong {
				display: block;
				text-transform: none;
				color: #cc3333;
			}
		}
        p {
            font-size: 24px;
            line-height: 30px;
            font-weight: 500;
            width: 700px;
            max-width: 100%;
            margin: 20px auto;
        }
	}
}
